



















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    totalPages: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  computed: {
    pagesToShow() {
      const pages = [];
      for (let i = this.currentPage; i < this.currentPage + 5 && i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  setup(props, {emit}) {
    const nextPage =async(inx) => {
      emit("nextPage", inx);
    }
    return {
      nextPage
    }
  },
})

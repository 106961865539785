<template>
  <div>
    <div class="account__wrapper">
      <div class="offers">
        <div class="container">
          <div class="section-header">
            <client-only>
              <div class="no-offer" v-if="noActiveInquiries">
                <h4>You have no inquiries yet.</h4>

                <nuxt-picture loading="lazy" src="/images/global/no-offer.svg" alt="No offer"/>
              </div>
              <div v-else>
                <div v-if="loading">
                  <SfLoader />
                </div>
                <div class="test" v-for="(item, index) in inquiryList " :key="index">
                  <div class="offer-box">
                    <div class="left">
                      <figure>
                        <nuxt-picture loading="lazy" :src="item.product.thumbnail.url" alt="item" />
                      </figure>
                      <!-- <span class="offer-box__counter">1x</span> -->
                      <h3>
                        <nuxt-link :to="localePath(encodeURI(`/p/${item.product.sku}/${item.product.url_key}.html`))">
                          {{item.product.name}}
                        </nuxt-link>
                        <span class="offer-box__status offer-box__status--accepted">For {{item.type == "Buy" ? "Sale" : item.type}}</span>
                      </h3>
                    </div>

                    <div class="offer-box__info">
                      <div>
                        <h6>Comment</h6>
                        <span>
                          {{item.comments}}
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
                <Pagination :currentPage="currentPage" :totalPages="totalPages" @nextPage="nextPage"/>
              </div>
            </client-only>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "~/almarwan/components/Breadcrumb.vue";
import Icon from "~/almarwan/components/Icon.vue";
import {eventBus} from "~/almarwan/helpers/EventBus";
import { useUser } from '~/modules/customer/composables/useUser';
import Pagination from "~/modules/customer/pages/MyAccount/Pagination.vue";
import {
    ref,
    onBeforeMount,
onMounted,
  } from '@nuxtjs/composition-api';
import {
  SfLoader,
} from '@storefront-ui/vue';
export default {
  name: "InquiriesTab",
  components: {
    Icon,
    SfLoader,
    BreadCrumb,
    Pagination
  },
  data() {
    return {
      currentTab: 'equipment',
    }
  },
  setup(_, { emit }) {
    const { isAuthenticated, inquiries } = useUser();
    const inquiryList = ref({})
    const loading = ref(false)
    const noActiveInquiries = ref(false);
    const breadcrumbs = [{
      link: '/customer/my-inquiries',
      text: 'Account'
    }]
    const currentPage = ref(1);
    const totalCount = ref(0);
    const totalPages = ref(0);
    const pageSize = ref(5)
    const getInquiries = async () => {
      loading.value = true
      if (isAuthenticated.value) {
        const res = await inquiries(pageSize.value, currentPage.value);
        inquiryList.value = res.items
        totalPages.value = res.totalPages;
        totalCount.value = res.total_count;
        loading.value = false;
        if(inquiryList.value.length == 0) {
          noActiveInquiries.value = true;
        }
      } else {
        toggleLoginModal();
      }
    };
    onBeforeMount(async () => {
      getInquiries();
    });
    const nextPage =async(inx) => {
      currentPage.value = inx;
      getInquiries();
    }
      return {
      getInquiries,
      inquiryList,
      loading,
      breadcrumbs,
      noActiveInquiries,
      currentPage,
      nextPage,
      totalCount,
      totalPages,
    }
  },
  methods: {
    toggleSidebar() {
      let sidebar = document.querySelector('.account__sidebar');

      if (sidebar.classList.contains('active')) {
        sidebar.classList.remove('active')
      } else {
        sidebar.classList.add('active')
      }
    },
    toggleSortList() {
      this.isSortListActive = !this.isSortListActive
    }
  },
  computed: {
    sortList() {
      const list = ['Last 30 days', 'Last 60 days', 'Last 90 days']
      return list.filter(item => item !== this.activeSort)
    }
  }
}
</script>

<template>
  <nav class="breadcrumb">
    <ul>
      <li>
        <nuxt-link :to="localePath('/')" :class="{'product-bc': product}">{{$t('Home')}}</nuxt-link>
      </li>
      <template v-if="applySlugColor">
        <template v-if="breadcrumbs">
          <template v-if="breadcrumbs" v-for="(breadcrumb, index) in breadcrumbs">
            <li :key="'slig-section-'+index">
              <nuxt-link :to="localePath(breadcrumb.link)">
                <span>{{ $t(breadcrumb.text) }}</span>
              </nuxt-link>
            </li>
          </template>
        </template>
        <template v-else>
          <li>
            <span>{{ $t(routeName) }}</span>
          </li>
        </template>
      </template>
      <template v-else-if="product">
        <template v-if="breadcrumbs.length>0">
          <template v-for="(breadcrumb, index) in breadcrumbs">
            <li :key="'prod-section-'+index" v-if="index < breadcrumbs.length-1" class="test">
              <nuxt-link :to="breadcrumb.link" class="product-bc">
                {{ $t(breadcrumb.text) }}
              </nuxt-link>
            </li>

            <li :key="index" v-if="index === breadcrumbs.length-1">
              <p>{{ productItemName.text }}</p>
            </li>
          </template>
        </template>
      </template>
      <template v-else>
        <template v-if="breadcrumbs">
          <template v-for="(breadcrumb, index) in breadcrumbs">
            <li :key="index" v-if="index === 0">
              <nuxt-link :to="localePath(breadcrumb.link)">
                {{ $t(breadcrumb.text) }}
              </nuxt-link>
            </li>
            <li :key="'last-section-'+index" v-if="index === 1">
              <nuxt-link :to="localePath(breadcrumb.link)">
                <span>{{ $t(breadcrumb.text['value']) }}</span>
              </nuxt-link>
            </li>
          </template>
        </template>
        <template v-else>
          <li>
            <span>{{ $t(routeName) }}</span>
          </li>
        </template>
      </template>
    </ul>
  </nav>
</template>
<script>
export default {
  name: "BreadCrumb",
  props: {
    breadcrumbs: {
      type: Array,
      default: [],
    },
    applySlugColor: {
      type: Boolean,
      default: false
    },
    product: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      routeName: ''
    }
  },
  mounted() {
    if(!this.breadcrumbs) {
      this.routeName = this.$router.currentRoute.path
      this.routeName = this.routeName.replace("/", "")
      let fisrtL = this.routeName.charAt(0)
      let upperLetter = fisrtL.toUpperCase()
      this.routeName = this.routeName.replace(`${fisrtL}`, `${upperLetter}`)
    }
  },
  computed: {
    productItemName () {
      return this.breadcrumbs[this.breadcrumbs.length-1];
    }
  }
}
</script>
